import React from "react";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import { Link } from "gatsby";

const StyledBackgroundImage = styled(BackgroundImage)`
  background-position: center center;
  background-size: cover;
  height: 14rem;

  a.over-link {
    display: block;
    height: 100%;
    width: 100%;
    position: relative;
    background-color: var(--gray);
    opacity: 0;
    &:hover {
      opacity: 0.3;
    }
  }

  h3 {
    margin: 0;
    text-transform: uppercase;
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    font-family: RalewaySemiBold;
    border-top: 2px solid white;
    // padding-left: 0.8rem;
    // padding-right: 1rem;
    padding: 1rem;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    left: 0rem;
    top: .8rem;
    width: 100%;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    &:hover {
      opacity: 1;
    }
  }
`;

const GoVisitorCenter = ({ fluid, url, name }) => {
  return (
    <StyledBackgroundImage Tag="div" fluid={fluid}>
      <Link to={url} className="over-link" target="_new"></Link>
      <Link to={url} target="_new">
        <h3>{name}</h3>
      </Link>
    </StyledBackgroundImage>
  );
};

export default GoVisitorCenter;
