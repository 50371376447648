import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import HeroPage from "../components/hero-page";
import GoVisitorCenters from "../components/go-visitor-centers";
import Seo from "../components/seo";

// relativePath: { eq: "Phil-closeup-crowd-APphoto-Carolyn-Kaster.jpg" }
// opengraphImage: file(relativePath: { eq: "Phil-closeup-crowd-APphoto-Carolyn-Kaster.jpg" }) {

const Discover = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(
        relativePath: { eq: "groundhog-day-2022.jpg" }
      ) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      opengraphImage: file(relativePath: { eq: "groundhog-day-2022.jpg" }) {
        sharp: childImageSharp {
          image: gatsbyImageData(layout: FIXED, width: 1200)
        }
      }
    }
  `);

  return (
    <>
      <Seo
        title="Discover PA Six, North Central Pennsylvania"
        description="Spend the day in Elk County searching for the majestic elk herd or venture to Jefferson County and see if you can identify all of the Phantastic Phil statutes throughout the town"
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="Discover PA Six"
        caption="Punxsutawney Phil - Photo by Anthony Quintano"
        position="40%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Discover the PA Six Region</h2>
            <p>
              Our region is one of breathtaking views, endless outdoor
              activities, and amazing activities to keep you entertained all
              year long.
            </p>
          </div>
          <div className="intro-text">
            <p>
              PA Six boasts limitless indoor and outdoor recreation
              opportunities. Come and hike the trails of our region’s Allegheny
              National Forest.
            </p>

            <p>
              Explore our stunning landscapes that change for every season,
              follow the majestic elk herd throughout our hills and valleys, and
              enjoy some of the most spectacular dark skies for stargazing
              available in the country. An abundance of wineries,
              micro-breweries, restaurants and distilleries will tempt your
              taste buds along your adventure.
            </p>

            <p>Whatever your interest you will find it in PA Six.</p>
          </div>
        </section>

        <section>
          <h2>Visitor Centers in PA Six</h2>
          <GoVisitorCenters />
        </section>
      </main>
    </>
  );
};

export default Discover;
