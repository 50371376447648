import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import GoVisitorCenter from "./go-visitor-center";
import { respondTo } from "../styles/respond-to";

const StyledCounties = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;

  ${
    respondTo.md`
      grid-template-columns: 1fr 1fr;
  `}

  ${
    respondTo.sm`
      grid-template-columns: 1fr;
  `}
`;

const GoVisitorCenters = () => {
  const data = useStaticQuery(graphql`
    query {
      pawilds: file(relativePath: { eq: "allegheny-forest-NathanWilson.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      potterTioga: file(relativePath: { eq: "grand-canyon-colton-vista.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      visitpago: file(relativePath: { eq: "RyanGist.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      mckean: file(
        relativePath: { eq: "east-branch-dam-TessaGrotzinger.jpg" }
      ) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      lumber: file(relativePath: { eq: "hiker.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      clearfield: file(relativePath: { eq: "fishing_2sz.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      potter: file(relativePath: { eq: "night-sky.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      elkCounty: file(relativePath: { eq: "PissedoffElk.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      kinzuaBridge: file(relativePath: { eq: "Kinzua-Bridge-Skywalk_web.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      routeSix: file(relativePath: { eq: "fountain-in-warren.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <StyledCounties>
      <GoVisitorCenter
        fluid={data.mckean.sharp.fluid}
        url="https://visitanf.com/"
        name="Allegheny National Forest Visitors Bureau"
      />
      <GoVisitorCenter
        fluid={data.clearfield.sharp.fluid}
        url="https://visitclearfieldcounty.org/"
        name="Clearfield County Visitors Center"
      />
      <GoVisitorCenter
        fluid={data.elkCounty.sharp.fluid}
        url="http://elkcountryvisitorcenter.com/"
        name="Elk Country Visitor Center"
      />
      <GoVisitorCenter
        fluid={data.kinzuaBridge.sharp.fluid}
        url="https://pawilds.com/asset/kinzua-bridge-state-park-visitor-center-sky-walk/"
        name="Kinzua Bridge State Park Visitor Center and Sky Walk"
      />
      <GoVisitorCenter
        fluid={data.lumber.sharp.fluid}
        url="https://lumberheritage.org/"
        name="Lumber Heritage Region"
      />
      <GoVisitorCenter
        fluid={data.routeSix.sharp.fluid}
        url="https://www.paroute6.com/"
        name="PA Route 6 Alliance"
      />
      <GoVisitorCenter
        fluid={data.pawilds.sharp.fluid}
        url="https://pawilds.com/"
        name="PA Wilds"
      />
      <GoVisitorCenter
        fluid={data.visitpago.sharp.fluid}
        url="https://visitpago.com/"
        name="Pennsylvania Great Outdoors"
      />
      <GoVisitorCenter
        fluid={data.potterTioga.sharp.fluid}
        url="https://www.visitpottertioga.com/"
        name="Potter-Tioga County Visitors Bureau"
      />
    </StyledCounties>
  );
};

export default GoVisitorCenters;
